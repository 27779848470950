import { AuthContext } from '@/AuthContext';
import { supabase } from '@/lib/supabaseClient';
import { Box, Spinner } from '@chakra-ui/react';
import { Footer, Header } from '@packages/ui/components';
import { theme } from '@packages/ui/constants';
import { deleteCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { MutationFunction } from 'react-query';
import { signOut as nextAuthSignOut } from 'next-auth/react';
import { retrieveBalance } from '@/services/withdrawalService';
interface PageLayoutProps {
  className?: string;
  loading?: boolean;
  children?: any;
  isDark?: boolean;
  height?: string | number;
  padding?: string;
  noFooter?: boolean;
}

export const PageLayout = ({
  loading,
  className,
  children,
  isDark,
  height,
  padding,
  noFooter,
}: PageLayoutProps) => {
  const router = useRouter();
  const {
    isAuthenticated,
    isQuestioner,
    setUser,
    setIsAuthenticated,
    hasNewNotifications,
    isLoading,
    profileImage,
    user,
  } = useContext(AuthContext);

  const signOut: MutationFunction<void, void> = async () => {
    const signOutResult = await supabase.auth.signOut();

    if (signOutResult.error) {
      throw signOutResult.error;
    }
    await nextAuthSignOut({ redirect: false, callbackUrl: '/' });
    deleteCookie('auth-session');
    deleteCookie('next-auth.session-token');
    setUser?.(null);
    setIsAuthenticated?.(false);
    localStorage.clear();
    router.replace('/login');
  };

  useEffect(() => {
    if (user?.user_metadata.accountName) {
      const redirect = localStorage.getItem("redirect") === "toCreateQuestion";
      setTimeout(() => {
        if (isAuthenticated && redirect) {
          const answererId = localStorage.getItem('fromQuestion');
          const path = {
            pathname: '/create-question',
            query: { answerer_id: answererId },
          };
          router.push(path);
        }
      }, 1000);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Box
        height={height || '100%'}
        bgColor={isDark ? theme.colors.background.dark : 'transparent'}
        padding={padding || '28px'}
        color={isDark ? theme.colors.background.white : theme.colors.background.dark}
        // overflowY="auto"
        marginTop="54px"
        className={className}
      >
        <Header
          retrieveBalance={retrieveBalance}
          isDarkTheme={isDark}
          isAuthenticated={isAuthenticated}
          isQuestioner={isQuestioner}
          signOut={signOut}
          newNotification={hasNewNotifications}
          isEmailLogin={user?.app_metadata?.provider === 'email'}
          profileImage={profileImage || user?.user_metadata?.twitterMetadata?.picture ||  ''}
          isInfluencerPage={router.asPath === "/"}
        />
        {loading || (isLoading && <Spinner />)}
        {children}
      </Box>
      {!noFooter && <Footer />}
    </>
  );
};
