import { theme, ChatCard, Text, Text10Gradient, ButtonLink } from '@packages/ui';
import { Box, Center, Flex, Link, Spacer, Text as ChakraText } from '@chakra-ui/react';
import LPLogo from '@packages/ui/assets/lp-logo.svg';
import { useTranslation } from 'next-i18next';
import HowToUseSVG from "@/public/assets/icons/how-to-use.svg";
import Worries from '@/public/assets/icons/worries.svg';
import FaqIcon from '@/public/assets/icons/faq.svg';
import ArrowDown from '@packages/ui/assets/icons/arrow-down.svg';
import BackgroundBallLeft from '@packages/ui/assets/icons/background-ball-left.svg';
import BackgroundBallRight from '@packages/ui/assets/icons/background-ball-right.svg';
import User1 from '@packages/ui/assets/icons/user1.svg';
import User2 from '@packages/ui/assets/icons/user2.svg';
import User3 from '@packages/ui/assets/icons/user3.svg';
import User4 from '@packages/ui/assets/icons/user4.svg';
import QMUser from '@packages/ui/assets/icons/qmuser.svg';
import React, { useContext } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { PageLayout } from '@/components/PageLayout';
import { AuthContext } from '@/AuthContext';
import Head from 'next/head';

const LandingPage = () => {
  const t = CommonTranslation();
  const { isAuthenticated } = useContext(AuthContext);
  const chatData = [
    {
      direction: 'incoming',
      content: "I recieve so many questions every day, it's hard to know which to answer first.",
      image: <User1 />,
    },
    {
      direction: 'outgoing',
      content:
        "I'm looking for a new source of income, but it's hard to find on other social networking sites.",
      image: <User2 />,
    },
    {
      direction: 'incoming',
      content: 'I get the same questions and have to give the same answers over and over again.',
      image: <User3 />,
    },
    {
      direction: 'outgoing',
      content: "I'm having trouble with questions that are difficult to answer or defamatory.",
      image: <User4 />,
    },
  ];

  return (
    <>
      <Head>
        <title>
          {`${t('Influencers in the Q&A world')} - Question Market 憧れのあの人に直接質問できる`}
        </title>
        <meta
          property="og:title"
          content={`${t(
            'Influencers in the Q&A world',
          )} - Question Market 憧れのあの人に直接質問できる`}
        />
        <meta name="description" content="Question Market（クエスチョンマーケット）は、Q&Aを主軸にした新サービスです。" />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}`} />
      </Head>
      <PageLayout padding="0px">
        <Box>
          <Box bg={theme.colors.background.footer}>
            <Center className="flex flex-col" padding="56px 67px 0 67px">
              <LPLogo />
              <ChakraText
                marginTop="18px"
                marginBottom="26px"
                textAlign="center"
                whiteSpace="pre"
                fontSize="34px"
                fontWeight={700}
                letterSpacing="1px"
                lineHeight={'54px'}
                textShadow={`-2px 1px 0px rgba(255, 255, 255, 1)`}
                style={{
                  WebkitTextStroke: `1px ${theme.colors.background.border}`,
                  WebkitTextFillColor: `transparent`,
                }}
              >
                {t(`Influencers in\nthe Q&A world`)}
              </ChakraText>
              <img src={"/assets/icons/mobile-influencers-page.png"}
                width="294px"
                height="294px"

                alt="Mobile"
              />
            </Center>
          </Box>
          <Box bg={theme.colors.background.border} paddingX="25px" paddingY="26px">
            <Center className="flex flex-col justify-center">
              <Flex direction="column" gap="9px">
                <Text
                  color="white"
                  size="16px"
                  text={t('# Get rewarded for answering questions.')}
                />
                <Text
                  color="white"
                  size="16px"
                  text={t('# More rewards when answers are viewed.')}
                />
                <Text color="white" size="16px" text={t('# Influencer-specific marketing')} />
              </Flex>
            </Center>
          </Box>
          {!isAuthenticated && <ButtonSection />}
          <Center className="flex flex-col">
            <Box width="100%" maxWidth="390px">
              <img src="/assets/icons/feature.svg" width={"100%"}
                height={"1627px"} alt="feature" />
            </Box>
          </Center>
          {!isAuthenticated && <ButtonSection />}
          <Box marginTop="72px">
            <Center className="flex flex-col">
              <Box width="100%" maxWidth="390px">
                <HowToUseSVG />
              </Box>
            </Center>
          </Box>
          <Box bg={theme.colors.background.border} className="-mt-12">
            <Center className="flex flex-col pt-20">
              <Box marginBottom="42px">
                <Box className="relative">
                  <Box>
                    <Text
                      text="よくある悩み"
                      color="white"
                      size="20px"
                      className="font-sans font-bold"
                    />
                  </Box>
                  <Box className="absolute -top-4 -left-3">
                    <Worries />
                  </Box>
                </Box>
              </Box>
              {chatData.map((x: any, index: number) => (
                <ChatCard
                  key={index}
                  direction={x.direction}
                  content={x.content}
                  dark={true}
                  userImageSvg={x.image}
                ></ChatCard>
              ))}
              <ArrowDown className="mb-[26px]" />
            </Center>
          </Box>
          <ButtonSection topText={true} />
          {Faq()}
          {!isAuthenticated && <ButtonSection />}
        </Box>
      </PageLayout>
    </>
  );
};

export default LandingPage;

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      // Will be passed to the page component as props
    },
  };
}

const CommonTranslation = () => {
  const { t } = useTranslation();
  return t;
};

function ButtonSection({ topText }: { topText?: boolean }) {
  const t = CommonTranslation();
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <Box bg={theme.colors.background.dark} paddingX="25px">
        <Center
          className="flex flex-col justify-center"
          h={topText ? '200px' : '142px'}
          color="white"
        >
          {topText ? (
            <Text
              center
              mb="20px"
              text={t(
                'With Question Market, you can enjoy Q&A with your fans while solving these problems!',
              )}
              color="white"
            />
          ) : null}
          {!isAuthenticated && (
            <ButtonLink variant="primary" rounded width="full" height="50px" href="https://line.me/R/ti/p/@946wxpjp" isExternal>
              {t('Click here to register')}
            </ButtonLink>
          )}
        </Center>
      </Box>
    </>
  )
};

const CardWithAbsoluteTitle = () => {
  const t = CommonTranslation();

  return (
    <>
      <Center className="flex flex-col">
        <Box className="relative" marginTop="69px" width="100%" maxWidth="390px">
          <Flex className="absolute top-0 left-0 w-full">
            <BackgroundBallLeft className="mt-14" />
            <Spacer />
            <BackgroundBallRight className="mt-[15rem]" />
          </Flex>
          <Box className="z-1">
            <Center className="flex flex-col" paddingX="27.5px">
              <Box className="relative" marginBottom="38px">
                <Box
                  bg={theme.colors.background.white}
                  borderRadius="7px"
                  boxShadow="0px 3px 15px rgba(0, 0, 0, 0.25);"
                  paddingX="18px"
                  paddingY="26px"
                >
                  <Box className="flex flex-col">
                    <Text size="10px" text="・500円〜任意の金額を設定します ・" />
                    <Text size="10px" text="・設定された金額以上で質問が届きます" />
                    <Text size="10px" text="・質問に回答したタイミングで報酬が発生します" />
                    <Text size="10px" text="・出金申請で随時現金引き出しが可能です" />
                    <Text size="10px" text="・手数料は質問金額の30%が発生します ・" />
                    <Text
                      size="10px"
                      text="・すでに回答した質問が閲覧された場合も報酬が発生します"
                    />
                    <Text
                      size="10px"
                      text="・手数料は閲覧金額の30%が発生し、手数料を除いた金額は質問者と回答者で折半報酬としてそれぞれに支払われます。"
                    />
                  </Box>
                </Box>
                <Text10Gradient
                  className="absolute -top-5 left-2"
                  text={t('Compensation')}
                  size="20px"
                  href="#"
                ></Text10Gradient>
              </Box>
              <Box className="relative" marginBottom="29px">
                <Box
                  bg={theme.colors.background.white}
                  borderRadius="7px"
                  boxShadow="0px 3px 15px rgba(0, 0, 0, 0.25);"
                  paddingX="18px"
                  paddingY="26px"
                >
                  <Text
                    size="10px"
                    text="・無料で対応していた質問への回答でお金を稼ぐことができる ㅤ"
                  />
                  <Text
                    size="10px"
                    text="・新しいサービスで、新たなファン獲得に繋げることができる"
                  />
                </Box>
                <Text10Gradient
                  className="absolute -top-5 left-2"
                  text={t('Benefits of Use')}
                  size="20px"
                  href='#'
                ></Text10Gradient>
              </Box>
            </Center>
          </Box>
        </Box>
      </Center>
    </>
  );
};

const Faq = () => {
  const t = CommonTranslation();

  const faqData = [
    {
      direction: 'incoming',
      content: '芸能事務所/ライバー事務所に所属していても大丈夫ですか？',
      image: <User1 />,
    },
    {
      direction: 'outgoing',
      content: '芸能事務所/ライバー事務所に所属している場合でも参加可能です。',
      image: <QMUser />,
    },
    {
      direction: 'incoming',
      content: 'アカウント作成にお金はかかりますか？',
      image: <User3 />,
    },
    {
      direction: 'outgoing',
      content: '登録費用は一切必要ございません。',
      image: <QMUser />,
    },
    {
      direction: 'incoming',
      content: '解答が返ってこなくても投げ銭は発生しますか？',
      image: <User1 />,
    },
    {
      direction: 'outgoing',
      content: '解答が返ってこない場合は、課金は発生しませんのでご安心ください。',
      image: <QMUser />,
    },
    {
      direction: 'incoming',
      content: '質問のみでも報酬が発生とはどういうことですか？',
      image: <User3 />,
    },
    {
      direction: 'outgoing',
      content:
        '質問と解答が閲覧されるごとに収益が配当されますので、インフルエンサーとファンの両方にメリットがあります。',
      image: <QMUser />,
    },
  ];
  return (
    <>
      {CardWithAbsoluteTitle()}
      <Center className="flex flex-col">
        <Box marginX="1.25rem" marginTop="62px" marginBottom="49px">
          <Box className="relative">
            <Box>
              <Text10Gradient text="よくある質問" size="20px" href="#" />
            </Box>
            <Box className="absolute -top-4 left-7">
              <FaqIcon />
            </Box>
          </Box>
        </Box>
        {faqData.map((x: any, index: number) => (
          <React.Fragment key={index}>
            <ChatCard
              direction={x.direction}
              content={x.content}
              dark={false}
              userImageSvg={x.image}
              outgoingMarginBottom="73px"
            ></ChatCard>
          </React.Fragment>
        ))}
        <Link
          isExternal
          href="https://wesionary-team.notion.site/Question-Market-b3d8e4cf77144660a9802d8ead319eea"
          color={theme.colors.background.subtext}
          fontWeight={700}
          mb="57px"
          mt="-10px"
          fontSize={13}
        >
          {t('Click here for other frequently asked questions.')}
        </Link>
      </Center>
    </>
  )
};
