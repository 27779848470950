import { API } from '@/lib/API';

const URL = '/api/withdrawal-request'

interface WithdrawalRequestProps {
    amount: number;
}

const retrieveBalance = async () => {
    const { data } = await API.get(URL);
    return data;
};

const requestWithdrawal = async (payload: WithdrawalRequestProps) => {
    const { data } = await API.post(URL, payload);
    return data;
}

const withdrawalHistory = async () => {
    const { data } = await API.get('/api/withdrawal-history');
    return data;
}

export { retrieveBalance, requestWithdrawal, withdrawalHistory };
